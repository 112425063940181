import React from "react";
import DataTip from "components/data-tip";
import PropTypes from "prop-types";
import { formatCurrency } from "utils";
import { ReactComponent as InfoTool } from "../../../../assets/icons/infoTool.svg";
import CardWhite from "newComponents/cardWhite";
import { useCurrencyEventContext } from "contexts/currency-event-context";
import { useLanguageSettings } from "hooks/language-settings";
import { useTranslation } from "react-i18next";
import { isWhitelabel } from "services/constants";

TransferCards.propTypes = {
  costs: PropTypes.number,
  isEventContext: PropTypes.bool,
  summary: PropTypes.shape({
    available: PropTypes.number,
    waiting: PropTypes.number,
    paid: PropTypes.number,
    analyse: PropTypes.number,
    in_analysis: PropTypes.number,
    pending: PropTypes.number,
    blocked: PropTypes.number,
    blockedPercentage: PropTypes.number,
    netBalance: PropTypes.number,
  }),
  isLoading: PropTypes.bool,
};

export function TransferCards({ summary, costs, isEventContext, isLoading }) {
  const { t } = useTranslation("transfers");
  const { currency: backofficeCurrency } = useLanguageSettings();
  const { currency: eventCurrency } = useCurrencyEventContext();
  const currency = isEventContext ? eventCurrency : backofficeCurrency;

  return isWhitelabel ? (
    <div id="card" className="col-12 mt-4">
      <div className="row">
        <div className="col-md-4  mb-3 pl-2 pr-2">
          <CardWhite
            name="availableBalance"
            title={t("transfers:cards.eventBalance")}
            value={formatCurrency(summary.available || 0, {
              currency,
              isCents: true,
            })}
            isLoading={isLoading}
          />
        </div>
        <div className="col-md-4  mb-3 pl-2 pr-2">
          <CardWhite
            name="transferanalyse"
            title={t("transfers:cards.transfersInAnalysis")}
            value={formatCurrency(summary.in_analysis || 0, {
              currency,
              isCents: true,
            })}
            isLoading={isLoading}
          />
        </div>
        <div className="col-md-4  mb-3 pl-2 pr-2">
          <CardWhite
            name="awaitingPayment"
            title={t("transfers:cards.transfersPaid")}
            value={formatCurrency(
              summary.paid || 0,
              {
                currency,
                isCents: true,
              }
            )}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  ) : (
    <div id="card" className="col-12 mt-4">
      <div className="row">
        <div className="col-md-3  mb-3 pl-2 pr-2">
          <CardWhite
            isLoading={isLoading}
            name="availableBalance"
            containerStyle={{
              minHeight: "150px",
            }}
            title={t("transfers:cards.allValueSold")}
            icon={
              <DataTip
                icon={<InfoTool width="1rem" height="1rem" />}
                tipContent={t("transfers:cards.allValueTooltip")}
                tipContainerClass="icon-white mt-1"
              />
            }
            value={formatCurrency(summary.netBalance, {
              currency,
              isCents: true,
            })}
            subTitle={
              <div className="d-flex align-items-center card-subtitle-mobile flex-grow-1">
                <span>
                  <p className="custom-card-subtitle">
                    {t("transfers:cards.netValueSubtitle", {
                      totalSales: formatCurrency(
                        Number(summary.netBalance + costs) || 0,
                        {
                          currency,
                          isCents: true,
                        }
                      ),
                      totalCosts: formatCurrency(costs, {
                        currency,
                        isCents: true,
                      }),
                    })}
                  </p>
                </span>
              </div>
            }
          />
        </div>
        <div className="col-md-3  mb-3 pl-2 pr-2">
          <CardWhite
            isLoading={isLoading}
            name="transferanalyse"
            containerStyle={{
              minHeight: "150px",
            }}
            title={t("transfers:cards.blocked")}
            value={formatCurrency(summary.blocked > 0 ? summary.blocked : 0, {
              currency,
              isCents: true,
            })}
            icon={
              <DataTip
                icon={<InfoTool width="1rem" height="1rem" />}
                tipContent={t("transfers:cards.blockedTooltip", {
                  blockedValue: summary.blockedPercentage,
                })}
                tipContainerClass="icon-white mt-1"
              />
            }
            subTitle={
              <div className="d-flex align-items-center card-subtitle-mobile flex-grow-1">
                <span>
                  <p className="custom-card-subtitle">
                    {t("transfers:cards.blockedSubtitle")}
                  </p>
                </span>
              </div>
            }
          />
        </div>
        <div className="col-md-3  mb-3 pl-2 pr-2">
          <CardWhite
            isLoading={isLoading}
            name="awaitingPayment"
            containerStyle={{
              minHeight: "150px",
            }}
            title={t("transfers:cards.avaliableToTransfer")}
            value={formatCurrency(
              summary.available > 0 ? summary.available : 0,
              {
                currency,
                isCents: true,
              }
            )}
            icon={
              <DataTip
                icon={<InfoTool width="1rem" height="1rem" />}
                tipContent={t("transfers:cards.availableTooltip")}
                tipContainerClass="icon-white mt-1"
              />
            }
            subTitle={
              <div className="d-flex align-items-center card-subtitle-mobile flex-grow-1">
                <span>
                  {summary.available <= 0 ? (
                    <p className="custom-card-subtitle">
                      {summary.available === 0
                        ? t("transfers:cards.zeroAvailableSubtitle")
                        : t("transfers:cards.negativeAvailableSubtitle", {
                            availableAmount: formatCurrency(
                              Math.abs(summary.available),
                              {
                                currency,
                                isCents: true,
                              }
                            ),
                          })}
                    </p>
                  ) : (
                    <>
                      <p className="custom-card-subtitle">
                        {t("transfers:cards.valuesAt")}
                      </p>
                    </>
                  )}
                </span>
              </div>
            }
          />
        </div>
        <div className="col-md-3  mb-3 pl-2 pr-2">
          <CardWhite
            name="awaitingPayment"
            isLoading={isLoading}
            title={t("transfers:cards.transfers")}
            value={formatCurrency(
              summary.paid +
                summary.waiting +
                summary.in_analysis +
                summary.pending,
              {
                currency,
                isCents: true,
              }
            )}
            icon={
              <DataTip
                icon={<InfoTool width="1rem" height="1rem" />}
                tipContent={t("transfers:cards.transfersTooltip")}
                tipContainerClass="icon-white mt-1"
              />
            }
            subTitle={
              <div className="d-flex align-items-center card-subtitle-mobile flex-grow-1 mt-2">
                <span>
                  <p className="custom-card-subtitle">
                    {`${formatCurrency(summary.pending + summary.in_analysis, {
                      currency,
                      isCents: true,
                    })} ${t("transfers:cards.pendingTransfers")}`}
                  </p>
                  <p className="custom-card-subtitle">
                    {`${formatCurrency(summary.waiting, {
                      currency,
                      isCents: true,
                    })} ${t("transfers:cards.waitingPayment")}`}
                  </p>
                  <p className="custom-card-subtitle">
                    {`${formatCurrency(summary.paid, {
                      currency,
                      isCents: true,
                    })} ${t("transfers:cards.paidTransfers")}`}
                  </p>
                </span>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
}
