import React from "react";

const statusStyles = {
  completed: {
    style: { color: "#00422A", backgroundColor: "#CEF2E5" },
    contentKey: "nominalTransfers.status.completed",
  },
  waiting: {
    style: { color: "#694C00", backgroundColor: "#FFE1D7" },
    contentKey: "nominalTransfers.status.waiting",
  },
  canceled: {
    style: { color: "#730A46", backgroundColor: "#FFE6E6" },
    contentKey: "nominalTransfers.status.canceled",
  },
  expired: {
    style: { color: "#171719", backgroundColor: "#FFEAB5" },
    contentKey: "nominalTransfers.status.expired",
  },
};

const ReturnStatus = ({ status, t }) => {
  const { style = statusStyles.completed.style, contentKey } =
    statusStyles[status] || {};

  const content = contentKey ? t(contentKey) : status;

  return (
    <div className="status-badge">
      <span
        style={{
          ...style,
          padding: "0.25rem",
          borderRadius: "100px",
          fontWeight: "bold",
        }}
      >
        {content}
      </span>
    </div>
  );
};

export default ReturnStatus;
