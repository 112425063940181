import React from "react";
import PropTypes from "prop-types";
import "./styles.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

CardWhite.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  value: PropTypes.string.isRequired,
  subTitle: PropTypes.node,
  containerStyle: PropTypes.object,
  isDashboard: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default function CardWhite({
  name,
  title,
  value,
  subTitle,
  icon,
  containerStyle,
  isDashboard,
  isLoading
}) {
  return (
    <div
      name={name}
      style={containerStyle}
      className="totalizer-transfer-card shadow-sm"
    >
      <p className={`totalizer-transfer-card-title ${!subTitle && 'text-center'}`} >
        {title}
        {icon}
      </p>
      {isLoading ? <>
        <Skeleton borderRadius={8} baseColor="#EAE9ED" className="first-skeleton"/>
        <Skeleton borderRadius={8} baseColor="#EAE9ED" count={2} />
      </> : (
        <>
      <p className={`totalizer-transfer-card-value primary-color-value ${!subTitle && 'text-center'}`}>{value}</p>
      {subTitle}
        </>
      )}
    </div>
  );
}
