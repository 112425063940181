import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FaRegCheckCircle, FaRegClock, FaRegTimesCircle } from "react-icons/fa";
import { RiFilePaperLine, RiPencilFill } from "react-icons/ri";
import { components } from "react-select";
import styled from "styled-components";
import Swal from "sweetalert2";
import * as yup from "yup";

import ApproveButton from "components/approveButton";
import FileUpload from "components/file-upload";
import ColorfulSelect from "newComponents/colorfulSelect";
import {
  destinyKeys,
  finalityKeys,
  isWhitelabel,
  transferTypeAccounts,
} from "services/constants";
import New_api from "services/new-api";
import { formatCurrency } from "utils";
import { ReactComponent as FileArrowUp } from "../../../assets/icons/fileArrowUp.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/DownloadSimple.svg";

import { ReactComponent as CheckIcon } from "../../../assets/icons/checkCircle.svg";
import { ReactComponent as ClockCircleIcon } from "../../../assets/icons/clockCircleIcon.svg";
import { ReactComponent as DeniedIcon } from "../../../assets/icons/denied.svg";
import { stylesByStatus } from "../transfer-status";
import { useTranslation } from "react-i18next";
import { dateFormatter } from "utils/date-formatter";

TransferInfos.propTypes = {
  transfer: PropTypes.object,
  statusList: PropTypes.array,
  transactionStatus: PropTypes.object,
  handleTransactionStatus: PropTypes.func,
  handleApproveOrRefuse: PropTypes.func,
  user: PropTypes.object,
  statusOptionsListBuilder: PropTypes.func,
  justificationArchive: PropTypes.object,
};

export default function TransferInfos({
  onClose,
  transfer,
  onEditTransfer,
  statusList,
  transactionStatus,
  handleTransactionStatus,
  handleApproveOrRefuse,
  currency,
  currencySymbol,
  statusOptionsListBuilder,
  defaultEventOrgInfo,
  justificationArchive,
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation("transfers");

  const review = t("transfers:badges.review").toUpperCase();
  const approved = t("transfers:badges.approved").toUpperCase();
  const paid = t("transfers:badges.paid").toUpperCase();
  const denied = t("transfers:badges.denied").toUpperCase();
  const requested = t("transfers:badges.requested").toUpperCase();
  const canceled = t("transfers:badges.canceled").toUpperCase();

  const labelToDefaultStatus = (label) => {
    switch (label) {
      case review:
        return "EM ÁNALISE";
      case paid:
        return "PAGO";
      case denied:
        return "RECUSADO";
      case approved:
        return "APROVADO";
      case canceled:
        return "CANCELADO";
      default:
        return "SOLICITADO";
    }
  };

  const statusIcons = {
    [review]: (
      <ClockCircleIcon
        width={18}
        height={18}
        stroke="#694C00"
        className="mr-2 mb-1"
      />
    ),
    [paid]: (
      <CheckIcon
        width={20}
        height={20}
        stroke="#00422A"
        className="mr-2 mb-1"
      />
    ),
    [denied]: <DeniedIcon width={18} height={18} className="mr-2 mb-1" />,
  };
  const [isOpen, setIsOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customStyles, setCustomStyles] = useState(
    stylesByStatus[labelToDefaultStatus(transactionStatus?.label)]
  );
  const user = JSON.parse(localStorage.getItem("user"));
  const statusOptions = statusList.map(({ value, label }) => ({
    value,
    label,
  }));

  const updateTransferReceiptSchema = yup.object({
    status: yup.string().nullable(),
    receipt: yup
      .string()
      .when("status", (status, field) =>
        status === "edit"
          ? field.required(t("transfers:validation.receipt"))
          : field.nullable()
      ),
  });

  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(updateTransferReceiptSchema),
  });

  const handleDownloadReceipt = () => {
    window.open(transfer.receipt_link, "_blank");
  };
  const handleDownloadJustification = () => {
    window.open(transfer.justification_document_link, "_blank");
  };

  const handleEditSuccess = (receiptLink) => {
    const updatedTransfer = {
      ...transfer,
      receipt_link: receiptLink,
    };

    onEditTransfer(updatedTransfer);
  };

  const handleEditReceipt = () => {
    setValue("status", "edit");
    setIsOpen(!isOpen);
  };

  const handleFormSubmit = (form) => {
    setLoading(true);

    New_api.put(`transfers/${transfer.id}`, {
      receipt_link: form.receipt,
    })
      .then(() => {
        Swal.fire({
          title: t("transfers:swal.edit.title"),
          icon: "success",
          confirmButtonText: "Ok",
        });
        handleEditSuccess(form.receipt);
        onClose();
      })
      .catch(() => {
        Swal.fire({
          title: t("transfers:swal.edit.error"),
          icon: "error",
          confirmButtonText: "Ok",
        });
      })
      .finally(() => setLoading(false));
  };

  const transactionStatusBuilder = ({
    id,
    backgroundColor,
    value,
    ...status
  }) => {
    const transferInfos = {
      requester_user_id: transfer.requester.id,
      event_id: transfer.event.id,
      transfer_transaction_status_id: value.id,
      transfer_transaction_id: transfer.id,
      user_id: user.id,
    };

    statusOptionsListBuilder(status.label);

    handleApproveOrRefuse(status.label, transferInfos);

    setCustomStyles(stylesByStatus[labelToDefaultStatus(status.label)]);

    handleTransactionStatus(status, transferInfos);
  };

  const SingleValue = ({ children, ...props }) => {
    return (
      <components.SingleValue {...props}>
        {!!statusIcons[children] && statusIcons[children]}
        {children}
      </components.SingleValue>
    );
  };

  return (
    <Container>
      <Row className="text-center">
        <Col>
          {transactionStatus?.label === requested && <PendingIcon />}
          {transactionStatus?.label === paid && (
            <SuccessIcon className="text-success" />
          )}
          {transactionStatus?.label === denied && (
            <RefusedIcon className="text-danger" />
          )}

          <StatusDescription>
            {transactionStatus?.label === requested &&
              t("transfers:pendingTransfer")}
            {transfer.status === paid && (
              <span className="text-success">
                {t("transfers:createdTransfer")}
              </span>
            )}
            {transactionStatus?.label === denied && (
              <span className="text-danger">
                {t("transfers:refusedTransfer")}
              </span>
            )}
          </StatusDescription>

          <TransferName>{transfer.name}</TransferName>
          <TransferValue>
            {currency !== "EUR" && (
              <TransferCurrencyValue>{currencySymbol} </TransferCurrencyValue>
            )}
            {formatCurrency(Math.round(transfer.amount), {
              isCents: true,
              removeSymbol: true,
              currency,
            })}
            {currency === "EUR" && (
              <TransferCurrencyValue> {currencySymbol}</TransferCurrencyValue>
            )}
          </TransferValue>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <ColorfulSelect
          id="colorful-select-transfer"
          isSearchable={false}
          customStyles={customStyles}
          options={statusOptions}
          value={transactionStatus}
          onChange={transactionStatusBuilder}
          isDisabled={!user.is_admin}
          components={{
            SingleValue,
          }}
        />
      </Row>

      {transactionStatus?.label === paid && transfer.receipt_link && (
        <Fragment>
          <Row className="justify-content-center">
            <Col md="5">
              <DownloadRecipe
                block
                variant="light"
                onClick={handleDownloadReceipt}
              >
                <RiFilePaperLine className="mr-2" />
                {t("transfers:detailsModal.downloadReceipt")}
              </DownloadRecipe>
            </Col>
          </Row>
          {user.is_admin === 1 && transactionStatus?.label === paid && (
            <Fragment>
              <Row className="justify-content-center">
                <Col md="5">
                  <EditTransferReceipt
                    block
                    variant="light"
                    onClick={handleEditReceipt}
                  >
                    <RiPencilFill className="mr-2" />
                    {t("transfers:form.fileUpload.editButton")}
                  </EditTransferReceipt>
                </Col>
              </Row>
            </Fragment>
          )}
        </Fragment>
      )}
      {isOpen && (
        <Form
          className="d-flex flex-column flex-grow-1"
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <Form.Group>
            <FileUpload
              id="transfer-file"
              url=""
              title={t("transfers:form.fileUpload.title")}
              label={t("transfers:form.fileUpload.label")}
              accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
              buttonTitle={t("transfers:form.fileUpload.buttonTitle")}
              description={t("transfers:form.fileUpload.description")}
              containerClassName="mt-2"
              fileTitle={t("transfers:form.fileUpload.fileTitle")}
              uploadFolder="transferencias"
              error={errors.receipt?.message}
              onChange={(url) => setValue("receipt", url)}
              onLoading={setUploading}
            />
            {errors.receipt?.message && (
              <div class="invalid-feedback d-block">
                {errors.receipt.message}
              </div>
            )}

            <Form.Control.Feedback type="invalid">
              {errors.refuse_reason?.message}
            </Form.Control.Feedback>

            <Row className="mt-3 row d-flex justify-content-end">
              <div className="col-md-5">
                <ApproveButton
                  type="submit"
                  disabled={uploading || loading}
                  title={
                    loading
                      ? t("transfers:form.sendingTransfer")
                      : t("transfers:form.editTransfer")
                  }
                />
              </div>
            </Row>
          </Form.Group>
        </Form>
      )}

      <TransferDivisor />

      <Row>
        <Col md="12">
          <InfoTitle>{t("transfers:detailsModal.requestedBy")}</InfoTitle>
          <InfoValue>
            {`${transfer.requester?.first_name} ${transfer.requester?.last_name}`}{" "}
            - {transfer.requester?.email}
          </InfoValue>

          <InfoTitle>{t("transfers:detailsModal.requestedAt")}</InfoTitle>
          <InfoValue>
            {dateFormatter(transfer.created_at, {
              shouldShowTime: true,
              locale: language,
            })}
          </InfoValue>

          <InfoTitle>{t("transfers:detailsModal.event")}</InfoTitle>
          <InfoValue>{transfer.event?.name || "-"}</InfoValue>

          {transfer?.justification_document_link && (
            <Row className="mt-2">
              <div className="col-1">
                <div
                  style={{
                    backgroundColor: "#F5F5F7",
                  }}
                  className="icon-container"
                >
                  <FileArrowUp fill="#1A212B" />
                </div>
              </div>
              <div className="col pl-5 pl-md-3">
                <div className="row align-items-center">
                  <div className="col col-md-3 d-flex flex-column">
                    <span className="text-link-label">
                      {justificationArchive?.type}
                    </span>
                    <span>{justificationArchive?.size}</span>
                  </div>
                  <div className="col">
                    <button
                      onClick={handleDownloadJustification}
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <DownloadIcon fill="none" />
                    </button>
                  </div>
                </div>
              </div>
            </Row>
          )}
        </Col>
      </Row>
      <TransferDivisor />

      <Row>
        <Col md="12">
          <InfoTitle>{t("transfers:detailsModal.beneficiary")}</InfoTitle>
          <InfoValue className="text-capitalize">
            {(transfer.name || "-").toLowerCase()}
          </InfoValue>

          {transfer.document && transfer.document.length > 14 ? (
            <InfoValue className="text-capitalize">
              {`CNPJ: ${(transfer.document || "-").toLowerCase()}`}
            </InfoValue>
          ) : (
            <InfoValue className="text-capitalize">
              {`CPF: ${(transfer.document || "-").toLowerCase()}`}
            </InfoValue>
          )}
          {transfer.method === "PIX" ? (
            <>
              <InfoTitle>{t("transfers:detailsModal.PIXKey")}</InfoTitle>
              <InfoValue>
                {transferTypeAccounts[transfer.type_account]} -{" "}
                {transfer.pix_key}
              </InfoValue>
            </>
          ) : (
            <>
              <InfoTitle>{t("transfers:detailsModal.bank")}</InfoTitle>
              <InfoValue>
                {transfer.bank?.code} - {transfer.bank?.name}
              </InfoValue>
            </>
          )}
        </Col>
      </Row>

      {transfer.method === "TED" && (
        <Row>
          <Col md="6">
            <InfoTitle>{t("transfers:detailsModal.agency")}</InfoTitle>
            <InfoValue>{transfer.agency}</InfoValue>
          </Col>
          <Col md="6">
            <InfoTitle>
              {t("transfers:detailsModal.account")}{" "}
              {transferTypeAccounts[transfer.type_account]}:
            </InfoTitle>
            <InfoValue>{transfer.account}</InfoValue>
          </Col>
        </Row>
      )}

      <TransferDivisor />

      <Row>
        <Col md="12">
          <InfoTitle>{t("transfers:detailsModal.finality")}</InfoTitle>
          <InfoValue className="text-capitalize">
            {finalityKeys[transfer.finality] ||
              t("transfers:table.notInformed")}
          </InfoValue>

          <InfoTitle>{t("transfers:detailsModal.observation")}</InfoTitle>
          <InfoValue className="text-capitalize">
            {transfer.observation || t("transfers:table.notInformed")}
          </InfoValue>
        </Col>
      </Row>

      <Row>
        <Col md="12">
          <InfoTitle>{t("transfers:detailsModal.destination")}</InfoTitle>
          <InfoValue className="text-capitalize">
            {destinyKeys[transfer.destiny] || t("transfers:table.notInformed")}
          </InfoValue>
        </Col>
      </Row>

      {(transfer.last_updated_status || transfer.refunse_reason) && (
        <TransferDivisor />
      )}

      <Row>
        <Col>
          {transfer.last_updated_status && (
            <>
              <InfoTitle>{t("transfers:detailsModal.paidAt")}</InfoTitle>
              <InfoValue>
                {dateFormatter(transfer.last_updated_status, {
                  shouldShowTime: true,
                  locale: language,
                })}
              </InfoValue>
            </>
          )}

          {transfer.refunse_reason && (
            <>
              <InfoTitle>{t("transfers:detailsModal.refuseReason")}</InfoTitle>
              <InfoValue>{transfer.refunse_reason}</InfoValue>
            </>
          )}
        </Col>
      </Row>

      {!!user.is_admin && !isWhitelabel && (
        <Fragment>
          <TransferDivisor />
          <Row>
            <Col>
              <InfoTitle>{t("transfers:detailsModal.organization")}</InfoTitle>
              <InfoValue>{defaultEventOrgInfo?.organization?.name}</InfoValue>
            </Col>
          </Row>
          <Row>
            <Col>
              <InfoTitle>{t("transfers:detailsModal.trustLevel")}</InfoTitle>
              <InfoValue>
                {defaultEventOrgInfo?.organization?.trustLevel?.name}
              </InfoValue>
            </Col>
          </Row>
          <Row>
            <Col>
              <InfoTitle>{t("transfers:detailsModal.companyDoc")}</InfoTitle>
              <InfoValue>
                {defaultEventOrgInfo?.organization?.document
                  ? defaultEventOrgInfo?.organization?.document
                  : "-"}
              </InfoValue>
            </Col>
          </Row>
          <Row>
            <Col>
              <InfoTitle>{t("transfers:detailsModal.responsible")}</InfoTitle>
              <InfoValue>
                {`${defaultEventOrgInfo?.user?.first_name} ${defaultEventOrgInfo?.user?.last_name}`}
              </InfoValue>
            </Col>
          </Row>
          <Row>
            <Col>
              <InfoTitle>{t("transfers:detailsModal.userDoc")}</InfoTitle>
              <InfoValue>
                {defaultEventOrgInfo?.organization?.owner_document}
              </InfoValue>
            </Col>
          </Row>
        </Fragment>
      )}
    </Container>
  );
}

//------ Styled Components -----//
const Container = styled.div`
  padding: 16px 0 0;
`;

const PendingIcon = styled(FaRegClock)`
  font-size: 31px;
  color: #4f6c7c;
`;

const SuccessIcon = styled(FaRegCheckCircle)`
  font-size: 31px;
`;
const RefusedIcon = styled(FaRegTimesCircle)`
  font-size: 31px;
`;

const StatusDescription = styled.p`
  margin: 0;
  font-weight: 400;
  color: #4f6c7c;
  font-size: 12px;
`;

const TransferName = styled.p`
  margin: 23px 0 0;
  font-size: 15px;
  font-weight: 400;
  color: #8fa5b2;
  text-transform: uppercase;
`;

const TransferValue = styled.p`
  margin: 5px 0 0;
  color: #4f6c7c;
  font-family: "Rubik", sans-serif;
  font-size: 45px;
  line-height: 44px;
`;

const TransferCurrencyValue = styled.span`
  font-size: 20px;
`;

const DownloadRecipe = styled(Button)`
  margin: 10px 0 0;
`;

const EditTransferReceipt = styled(Button)`
  margin: 10px 0 0;
`;

const TransferDivisor = styled.hr`
  margin: 21px 0 0;
  border-top: 2px dashed #bacad3;
`;

const InfoTitle = styled.p`
  color: #4f6c7c;
  font-weight: 500;
  font-size: 15px;
  margin: 14px 0 0;
`;

const InfoValue = styled.p`
  color: #8fa5b2;
  font-weight: 400;
  font-size: 12px;
  margin: 0;
`;
