import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Swal from "sweetalert2";

import { BiLoaderAlt, BiTrashAlt } from "react-icons/bi";
import {
	BsChevronDown,
	BsEye,
	BsEyeSlash,
	BsLink45Deg,
	BsPencil,
	BsPlusCircle,
} from "react-icons/bs";

import { COLORS, SPACINGS } from "services/constants";

import Toast from "components/Toast/Toast";
import { useCurrencyEventContext } from "contexts/currency-event-context";
import moment from "moment";
import { useTranslation } from "react-i18next";
import api from "services/api";
import New_api from "services/new-api";
import { formatCurrency } from "utils";
import IngressosTable from "./IngressosTable";
import ModalEditLot from "./ModalEditLot";
import ModalPassportLot from "./ModalPassportLot";
import ModalPublic from "./ModalPublic";
import ModalTicketType from "./ModalTicketType";
import ModalVincular from "./ModalVincular";

LoteTable.propTypes = {
  sectorId: PropTypes.any,
  eventId: PropTypes.any,
  lotes: PropTypes.shape({
    id: PropTypes.string,
    lot: PropTypes.shape({
      name: PropTypes.string,
      expiration: PropTypes.string,
    }),
    status: PropTypes.number,
    qty: PropTypes.number,
    total_sold: PropTypes.number,
    total_available: PropTypes.number,
    isPassport: PropTypes.bool,
    value: PropTypes.number,
    types: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        value: PropTypes.number,
        sold: PropTypes.number,
        qty: PropTypes.number,
        pending: PropTypes.number,
        active: PropTypes.number,
        is_public: PropTypes.number,
        available: PropTypes.number,
        type: PropTypes.string,
      })
    ),
  }),
  setRefetch: PropTypes.func,
  refetch: PropTypes.bool,
  loteNumber: PropTypes.number,
  eventSectorLotId: PropTypes.number,
  loteNames: PropTypes.arrayOf(PropTypes.string),
  setReload: PropTypes.func,
  isAutomaticTurn: PropTypes.bool,
};

const WIDTH_MULTIPLIER = 1.2; // 1.2 pois tem 120 de largura
const MIN_WIDTH_PROGRESS_BAR = 6; // minimo pra border radius 3 funcionar (3 de cada lado)
const MAX_WIDTH_PROGRESS_BAR = 120;

export default function LoteTable(props) {
  const {
    lotes,
    setRefetch,
    loteNames,
    setReload,
    isPassport,
    sectorId,
    eventId,
    isAutomaticTurn,
  } = props;
  const [loteData, setLoteData] = React.useState(lotes);
  const [deleteLotLoading, setDeleteLotLoading] = React.useState(false);
  const [getTicketNameLoading, SetGetTicketNameLoading] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [lotIsOpen, setLotIsOpen] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [ticketTypeIsOpen, setTicketTypeIsOpen] = React.useState(false);
  const [toastConfs, setToastConfs] = React.useState({
    type: "",
    toastText: "",
    showTime: 0,
    showToast: false,
  });
  const [isPublic, setIsPublic] = React.useState(loteData.types[0]?.is_public);
  const [publicIsLoading, setPublicIsLoading] = React.useState(false);
  const [modalVincularIsOpen, setModalVincularIsOpen] = React.useState(false);
  const [modalPublicIsOpen, setModalPublicIsOpen] = React.useState(false);
  const { t } = useTranslation("components", {
    keyPrefix: "tickets.lot-table",
  });
  const { currency } = useCurrencyEventContext();

  React.useEffect(() => {
    setLoteData(lotes);
  }, [lotes]);

  const [ticketDataToModal, setTicketDataToModal] = React.useState({
    id: 0,
    name: "",
    expiration: "",
    min_purchase: null,
    max_purchase: null,
    cpf_max_purchase: null,
    cpf_limit: null,
    description: "",
    value: 0,
    qty: 0,
    price: 0,
    passport_tickets: [],
    ticket_sale_channel_ids: [],
  });

  const changedTypes = loteData.types.map((type) => {
    return {
      id: type.id,
      active: type.active,
      qty: type.qty,
    };
  });

  const [ticketData, setTicketData] = React.useState({
    sectorLot: {
      id: loteData.id,
      status: loteData.status,
      qty: loteData.qty,
    },
    types: changedTypes,
  });

  function calcPercentVendidos(vendidos, totalIngressos) {
    let percentVendidos = (100 * vendidos) / totalIngressos;

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(percentVendidos) || percentVendidos === 0) {
      return 0;
    }

    percentVendidos = Math.floor(percentVendidos * WIDTH_MULTIPLIER);

    if (percentVendidos < MIN_WIDTH_PROGRESS_BAR) {
      return MIN_WIDTH_PROGRESS_BAR;
    }

    return Math.min(MAX_WIDTH_PROGRESS_BAR, percentVendidos);
  }

  function deleteLote() {
    setDeleteLotLoading(true);

    New_api.delete(`/event-sector-lots/${loteData.id}`)
      .then(() => {
        setRefetch(true);
        Swal.fire({
          title: t("alert.remove.text"),
          icon: "success",
          text: t("alert.remove.text"),
          confirmButtonText: t("alert.button-text"),
        });
      })
      .catch((err) => {
        Swal.fire({
          title: t("alert.error.title"),
          icon: "error",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#95aac9",
          text: t(
            err.response.status === 422
              ? "alert.error.restriction"
              : "alert.error.remove"
          ),
          confirmButtonText: t("alert.button-text"),
        });
      })
      .finally(() => {
        setDeleteLotLoading(false);
      });
  }

  function sendChanges() {
    setIsLoading(true);

    const { sectorLot } = ticketData;
    const typeDataToSend = { sectorLot };

    typeDataToSend.sectorLot.status = ticketData.sectorLot.status === 0 ? 1 : 0;

    api
      .post("update/sectorlot", typeDataToSend)
      .then(() => {
        setToastConfs({
          type: "success",
          toastText: t("toast.update-lot.success"),
          showTime: 3500,
          showToast: true,
        });
      })
      .catch(() => {
        setToastConfs({
          type: "error",
          toastText: t("toast.update-lot.error"),
          showTime: 3500,
          showToast: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  function getTicketNameAndOpenModal() {
    SetGetTicketNameLoading(true);
    api
      .get(`/event/sector/lot/${loteData.id}`)
      .then((res) => {
        let expiration = null;

        if (res.data.expiration) {
          expiration = moment(res.data.expiration).format("yyyy-MM-DDTHH:mm");
        }

        setTicketDataToModal({
          id: res.data.id,
          name: loteData.lot.name,
          expiration,
          min_purchase: res.data.min_purchase ? res.data.min_purchase : null,
          max_purchase: res.data.max_purchase ? res.data.max_purchase : null,
          cpf_max_purchase: res.data.cpf_max_purchase
            ? res.data.cpf_max_purchase
            : null,
          cpf_limit: res.data.cpf_limit ? res.data.cpf_limit : null,
          description: res.data.description ? res.data.description : "",
          value: res.data.value ? res.data.value : 0,
          qty: res.data.qty ? res.data.qty : 0,
          price: res.data.price ? res.data.price : 0,
          passport_tickets: res.data.passportTickets,
          ticket_sale_channel_ids: res.data.ticketSaleChannels.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        });
        setIsOpen(true);
      })
      .catch(() => {
        Swal.fire({
          title: t("alert.error.title"),
          icon: "error",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#95aac9",
          text: t("alert.error.load"),
          confirmButtonText: t("global:confirmButtonText"),
        });
      })
      .finally(() => {
        SetGetTicketNameLoading(false);
      });
  }

  function handleOpenTickets() {
    if (!isPassport) {
      setLotIsOpen(!lotIsOpen);
    }
  }

  function checkPublicAndOpenModal() {
    if (isPublic) {
      setModalPublicIsOpen(true);
      return;
    }
    setModalVincularIsOpen(true);
  }

  function togglePublic() {
    setPublicIsLoading(true);

    const changePublic = {
      id: loteData.types[0]?.id,
      isPublic: !isPublic,
    };

    api
      .post("event/ticket/type/public", changePublic)
      .then(() => {
        setIsPublic(!isPublic);
        setToastConfs({
          type: "success",
          toastText: t("toast.update-ticket.success"),
          showTime: 3500,
          showToast: true,
        });
      })
      .catch(() => {
        setToastConfs({
          type: "error",
          toastText: t("toast.update-ticket.error"),
          showTime: 3500,
          showToast: true,
        });
      })
      .finally(() => {
        setPublicIsLoading(false);
      });
  }

  function createTicketTypeCallback(res) {
    const {id, qty} = res

    setTicketData({
      ...ticketData,
      types: [
        ...ticketData.types,
        {
          id,
          active: 1,
          qty: Number(qty)
        }
      ]
    })
  }

  return (
    <LotePaddingContainer>
      <LoteInfoContainer
        className="align-items-center"
        style={
          lotIsOpen
            ? {
                backgroundColor: COLORS.lightBlue,
                height: `${
                  (!loteData.types.length ? 2 : loteData.types.length + 1) *
                    42 +
                  2
                }px`,
                borderLeft: `5px ${COLORS.darkBlue} solid`,
                borderBottom: "1px #EDF2F9 solid",
                borderTop: "1px #EDF2F9 solid",
                borderRight: "1px #EDF2F9 solid",
                transition: "all 350ms ease-in-out",
              }
            : {
                backgroundColor: COLORS.lightGray,
                height: "42px",
                transition: "all 350ms ease-in-out",
                paddingLeft: "4px",
                borderLeft: "1px #EDF2F9 solid",
                borderBottom: "1px #EDF2F9 solid",
                borderTop: "1px #EDF2F9 solid",
                borderRight: "1px #EDF2F9 solid",
              }
        }
      >
        <LoteContainer className="col-12 row p-0 m-0">
          <IsOpenContainer
            className="col-3 justify-content-start align-itens-center p-0"
            style={{ minWidth: "160px" }}
            onClick={handleOpenTickets}
          >
            {!isPassport && (
              <ArrowIconContainer>
                <BsChevronDown
                  size={12}
                  style={
                    lotIsOpen
                      ? {
                          transform: "rotate(0deg)",
                          transition: "all 350ms ease-in-out",
                          color: COLORS.darkBlue,
                        }
                      : {
                          transform: "rotate(-90deg)",
                          transition: "all 350ms ease-in-out",
                          color: COLORS.gray,
                        }
                  }
                />
              </ArrowIconContainer>
            )}
            <NomeLoteText>{loteData.lot.name}</NomeLoteText>
          </IsOpenContainer>
          <IsOpenContainer className="col-1 p-0" onClick={handleOpenTickets}>
            <ValueLoteText>
              {formatCurrency(loteData.value, { isCents: false, currency })}
            </ValueLoteText>
          </IsOpenContainer>
          <SoldAvailableContainer className="col-3">
            <IsOpenContainer
              className="justify-content-end"
              onClick={handleOpenTickets}
            >
              <InputVendido
                style={
                  lotIsOpen
                    ? {
                        backgroundColor: COLORS.lightBlue,
                        transition: "all 350ms ease-in-out",
                      }
                    : {
                        backgroundColor: COLORS.lightGray,
                        transition: "all 350ms ease-in-out",
                      }
                }
                disabled
                value={loteData.total_sold}
              />
              <InputPendente
                style={
                  lotIsOpen
                    ? {
                        backgroundColor: COLORS.lightBlue,
                        transition: "all 350ms ease-in-out",
                      }
                    : {
                        backgroundColor: COLORS.lightGray,
                        transition: "all 350ms ease-in-out",
                      }
                }
                disabled
                value={Math.max(
                  0,
                  loteData.qty -
                    (loteData.total_sold + loteData.total_available)
                )}
              />
            </IsOpenContainer>
          </SoldAvailableContainer>
          <SoldAvailableContainer className="col-2 pl-2">
            <IsOpenContainer onClick={handleOpenTickets}>
              <InputDisponivel
                style={
                  lotIsOpen
                    ? {
                        backgroundColor: COLORS.lightBlue,
                        transition: "all 350ms ease-in-out",
                      }
                    : {
                        backgroundColor: COLORS.lightGray,
                        transition: "all 350ms ease-in-out",
                      }
                }
                disabled
                value={loteData.qty}
              />
              <div style={{ width: "40px" }} />
            </IsOpenContainer>
            <ProgressBarContainer>
              <IsOpenContainer
                style={{ height: "42px" }}
                onClick={handleOpenTickets}
              >
                <ProgressBarContentContainer>
                  <ProgressPendenteBarProgressContainer
                    style={{
                      width: calcPercentVendidos(
                        loteData.total_sold +
                          loteData.qty -
                          (loteData.total_sold + loteData.total_available),
                        loteData.qty
                      ),
                    }}
                  />
                  <ProgressBarProgressContainer
                    style={{
                      width: calcPercentVendidos(
                        loteData.total_sold,
                        loteData.qty
                      ),
                      marginLeft: -calcPercentVendidos(
                        loteData.total_sold +
                          loteData.qty -
                          (loteData.total_sold + loteData.total_available),
                        loteData.qty
                      ),
                    }}
                  />
                </ProgressBarContentContainer>
              </IsOpenContainer>
            </ProgressBarContainer>
          </SoldAvailableContainer>
          <DisponibilidadeContainer className="col-2 p-0">
            <DisponibilidadeSpacingContainer onClick={handleOpenTickets} />
            {isLoading && (
              <LoadingContainer>
                <LoadingIcon color={COLORS.darkBlue} size={20} />
              </LoadingContainer>
            )}
            <IsOpenContainer
              style={{
                height: "42px",
                alignItems: "center",
                width: "100%",
              }}
              onClick={() => sendChanges()}
            >
              {ticketData.sectorLot.status ? (
                <BsEye
                  style={{
                    transition: "all 350ms ease-in-out",
                    opacity: 1,
                  }}
                  color={COLORS.darkGray}
                  size={14}
                />
              ) : (
                <BsEyeSlash
                  style={{
                    opacity: 0.4,
                    transition: "all 350ms ease-in-out",
                  }}
                  color={COLORS.gray}
                  size={14}
                />
              )}

              <DisbonibilidadeText
                style={{
                  color: "#4f6c7c",
                  opacity: ticketData.sectorLot.status ? 1 : 0.4,
                }}
              >
                {ticketData.sectorLot.status ? t("visible") : t("invisible")}
              </DisbonibilidadeText>
            </IsOpenContainer>
          </DisponibilidadeContainer>

          <ActionButtonsContainer className="col-1 justify-content-end p-0">
            <ActionButtons onClick={() => getTicketNameAndOpenModal()}>
              {getTicketNameLoading ? (
                <LoadingIcon color={COLORS.darkBlue} size={14} />
              ) : (
                <BsPencil color={COLORS.darkGray} size={14} />
              )}
            </ActionButtons>
            <ActionButtons onClick={() => deleteLote()}>
              {deleteLotLoading ? (
                <LoadingIcon color={COLORS.darkBlue} size={14} />
              ) : (
                <BiTrashAlt color={COLORS.darkGray} size={14} />
              )}
            </ActionButtons>

            {!isPassport && (
              <ActionButtons onClick={() => setTicketTypeIsOpen(true)}>
                <BsPlusCircle color={COLORS.darkGray} size={14} />
              </ActionButtons>
            )}

            {isPassport && (
              <>
                <ActionButtons onClick={() => checkPublicAndOpenModal()}>
                  <BsLink45Deg
                    style={
                      loteData.types[0]?.active
                        ? {
                            transition: "all 350ms ease-in-out",
                            opacity: 1,
                          }
                        : {
                            opacity: 0.4,
                            transition: "all 350ms ease-in-out",
                          }
                    }
                    color={isPublic ? COLORS.gray : COLORS.darkGray}
                    size={14}
                  />
                </ActionButtons>

                <ActionButtons onClick={() => togglePublic()}>
                  {publicIsLoading ? (
                    <LoadingIcon color={COLORS.darkBlue} size={14} />
                  ) : isPublic ? (
                    <BsEye
                      style={
                        loteData.types[0]?.active
                          ? {
                              transition: "all 350ms ease-in-out",
                              opacity: 1,
                            }
                          : {
                              opacity: 0.4,
                              transition: "all 350ms ease-in-out",
                            }
                      }
                      color={COLORS.darkGray}
                      size={14}
                    />
                  ) : (
                    <BsEyeSlash
                      style={
                        loteData.types[0]?.active
                          ? {
                              transition: "all 350ms ease-in-out",
                              opacity: 1,
                            }
                          : {
                              opacity: 0.4,
                              transition: "all 350ms ease-in-out",
                            }
                      }
                      color={COLORS.gray}
                      size={14}
                    />
                  )}
                </ActionButtons>
              </>
            )}
          </ActionButtonsContainer>
        </LoteContainer>
        {loteData.types.length === 0 && lotIsOpen && (
          <p className="pl-3 pt-1">{t("noTicketFound")}</p>
        )}
        {loteData.types.map((type) => (
          <IngressosTable
            isOpen={lotIsOpen}
            key={type.id}
            type={type}
            ticketState={{ ticketData, setTicketData }}
            calcPercentVendidos={calcPercentVendidos}
            loteQty={loteData.qty}
            setRefetch={setRefetch}
          />
        ))}
      </LoteInfoContainer>
      {toastConfs.showToast && (
        <Toast
          type={toastConfs.type}
          toastText={toastConfs.toastText}
          showToast={toastConfs.showToast}
          setToastConfs={setToastConfs}
          showTime={toastConfs.showTime}
        />
      )}

      {!isPassport ? (
        <ModalEditLot
          isPassport={isPassport}
          toggleFunction={{ isOpen, setIsOpen }}
          ticketState={{ ticketDataToModal, setTicketDataToModal }}
          loteDataState={{ loteData, setLoteData }}
          loteNames={loteNames}
          setRefetch={setRefetch}
          isAutomaticTurn={isAutomaticTurn}
        />
      ) : (
        <ModalPassportLot
          setRefetch={setRefetch}
          eventId={parseInt(eventId)}
          sectorId={parseInt(sectorId)}
          sectorLot={ticketDataToModal}
          toggleFunction={{ isOpen, setIsOpen }}
          isPassport={true}
          isAutomaticTurn={isAutomaticTurn}
        />
      )}

      <ModalTicketType
        toggleFunction={{ ticketTypeIsOpen, setTicketTypeIsOpen }}
        lotes={lotes}
        setRefetch={setRefetch}
        setReload={setReload}
        total_available={loteData.qty}
        createCallback={createTicketTypeCallback}
      />

      <ModalVincular
        toggleFunction={{ modalVincularIsOpen, setModalVincularIsOpen }}
        typeId={loteData.types[0]?.id}
      />

      <ModalPublic
        toggleFunction={{ modalPublicIsOpen, setModalPublicIsOpen }}
        togglePublic={togglePublic}
      />
    </LotePaddingContainer>
  );
}
// /////////////////////////////////// CONTAINERs ////////////////////////
const LoadingContainer = styled.div`
  min-width: 32px;
  display: flex;
  justify-content: center;
`;
const DisponibilidadeContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`;
const SoldAvailableContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`;

const DisponibilidadeSpacingContainer = styled.div`
  display: none;
  @media (min-width: 1150px) {
    height: 42px;
    align-items: center;
    width: 100%;
    display: flex;
  }
`;

const IsOpenContainer = styled.button`
  margin: 0;
  border: none;
  display: flex;
  background-color: transparent;
  border: none;
  align-items: center;
  width: 100%;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const LotePaddingContainer = styled.div`
  padding-bottom: ${SPACINGS.space2};
`;

const LoteInfoContainer = styled.div`
  border-radius: 5px;
`;

const ProgressBarContainer = styled.div`
  display: none;
  @media (min-width: 1150px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;
const LoteContainer = styled.div`
  display: flex;
  background-color: transparent;
  border: none;
  align-items: center;
  height: 42px;
`;
const ArrowIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 ${SPACINGS.space2} 0 ${SPACINGS.space2};
`;
const ProgressBarContentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 6px;
  border-radius: 3px;
  width: 120px;
  background-color: ${COLORS.weakGray};
`;

const ProgressBarProgressContainer = styled.div`
  height: 6px;
  border-radius: 3px;
  width: 30px;
  background-color: ${COLORS.middleBlue};
`;

const ProgressPendenteBarProgressContainer = styled.div`
  height: 6px;
  border-radius: 3px;
  width: 30px;
  background-color: #44d1f4;
`;

// /////////////////////////////////// TEXTs //////////////////////////////

const DisbonibilidadeText = styled.p`
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  font-size: 12px;
  padding: 2px 0 0 ${SPACINGS.space1};
`;

const ValueLoteText = styled.p`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: left;
  text-align: center;
  height: 32px;
  font-size: 12px;
  color: ${COLORS.darkGray};
`;

const NomeLoteText = styled.span`
  margin: 0;
  display: inline-block;
  text-align: start;
  line-height: 40px;
  font-size: 12px;
  padding-left: ${SPACINGS.space0};
  color: ${COLORS.darkGray};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 160px;
`;

// /////////////////////////////////// INPUTs /////////////////////////////
const InputVendido = styled.input`
  color: ${COLORS.darkGray};
  outline: none;
  text-align: center;
  border: none;
  width: 65px;
  font-size: 12px;
  border-radius: 5px 0 0 5px;
  filter: brightness(96%);
  height: 25px;
  cursor: pointer;
`;

const InputPendente = styled.input`
  color: ${COLORS.gray};
  outline: none;
  border: none;
  width: 65px;
  text-align: center;
  font-size: 12px;
  border-radius: 0 5px 5px 0;
  filter: brightness(96%);
  height: 25px;
  cursor: pointer;
`;

const InputDisponivel = styled.input`
  color: ${COLORS.darkGray};
  outline: none;
  border: none;
  width: 65px;
  text-align: center;
  font-size: 12px;
  border-radius: 5px;
  filter: brightness(96%);
  height: 25px;
  cursor: pointer;
`;

// /////////////////////////////////// BUTTONs ////////////////////////////
const ActionButtons = styled.button`
  border: none;
  background-color: transparent;
  padding: 0 ${SPACINGS.space2} 0 0;
`;

// /////////////////////////////////// ICONs //////////////////////////////

const LoadingIcon = styled(BiLoaderAlt)`
  animation: rotate 1.5s linear infinite;
  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
`;
