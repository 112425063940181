import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Modal from "react-modal";
import { ToastContainer } from "react-toastr";
import Swal from "sweetalert2";
import { IoIosCloseCircleOutline, IoMdArrowBack } from "react-icons/io";
import { COLORS, SPACINGS } from "services/constants";
import { breakPoints } from "services/breakPoints";
import api from "../../services/api";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

Modal.setAppElement("#root");
ModalTicketType.propTypes = {
  toggleFunction: PropTypes.shape({
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
  }),
  lotes: PropTypes.shape({
    id: PropTypes.string,
    lot: PropTypes.shape({
      name: PropTypes.string,
      expiration: PropTypes.string,
    }),
    status: PropTypes.number,
    qty: PropTypes.number,
    total_sold: PropTypes.number,
    total_available: PropTypes.number,
    value: PropTypes.number,
    types: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        value: PropTypes.number,
        sold: PropTypes.number,
        qty: PropTypes.number,
        pending: PropTypes.number,
        active: PropTypes.number,
        is_public: PropTypes.number,
        available: PropTypes.number,
        type: PropTypes.string,
      })
    ),
  }),
  setRefetch: PropTypes.func,
  setReload: PropTypes.func,
  createCallback: PropTypes.func,
};

export default function ModalTicketType(props) {
  // eslint-disable-next-line no-unused-vars
  let container;
  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState();
  const [percentage, setPercentage] = useState();
  const {
    toggleFunction: { ticketTypeIsOpen, setTicketTypeIsOpen },
    lotes: { id },
    total_available,
    setReload,
  } = props;
  const [error, setError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation("components", {
    keyPrefix: "tickets.modal-ticket-type",
  });

  const { handleSubmit, errors } = useForm();

  React.useEffect(() => {
    if (!ticketTypeIsOpen) {
      setError(false);
      setName("");
      setQuantity();
      setPercentage();
    }
    // eslint-disable-next-line
  }, [ticketTypeIsOpen]);

  async function createTicketType() {
    if (!name) {
      setError(t("validation-error.name-required"));
      return;
    } else if (!quantity) {
      setError(t("validation-error.quantity-required"));
      return;
    } else if (total_available < quantity) {
      setError(t("validation-error.quantity-available"));
      return;
    } else if (!percentage) {
      setError(t("validation-error.percentage-required"));
      return;
    } else if (percentage < 0.0 || percentage > 100.0) {
      setError(t("validation-error.percentage-valid-value"));
      return;
    }

    setIsSubmitting(true);

    const ticketTypeData = {
      eventSectorLotId: id,
      ticketTypeName: name,
      ticketTypeDiscount: percentage,
      ticketTypeQuantity: quantity,
      ticketTypeDiscountType: "percent",
      isActive: 1,
      isPublic: 1,
      minimumPurchase: 1,
    };

    api
      .post("/event/ticket-type", ticketTypeData)
      .then(async (response) => {
        const res = await response.data;
        Swal.fire({
          title: t("alert.title"),
          icon: "success",
          confirmButtonColor: "#23b936",
          cancelButtonColor: "#188125",
          confirmButtonText: t("alert.button-text"),
        });

        setTimeout(() => {
          setTicketTypeIsOpen(false);
          setIsSubmitting(false);
        }, "1250");

        setReload(true);
        props.createCallback(res)
        return res;
      })
      .catch(() => {
        Swal.fire({
          title: t("alert.error"),
          icon: "danger",
          confirmButtonColor: "#23b936",
          cancelButtonColor: "#188125",
          confirmButtonText: t("alert.button-text"),
        });
        setIsSubmitting(false);
        return false;
      });
  }

  return (
    <ModalComponent
      isOpen={ticketTypeIsOpen}
      onRequestClose={() => setTicketTypeIsOpen(false)}
      className="shadow side-modal"
      closeTimeoutMS={500}
    >
      <ToastContainer
        ref={(ref) => (container = ref)}
        className="toast-top-right"
      />

      <ModalContainer>
        <HeaderContainer>
          <ArrowTextContainer>
            <BackArrowIcon
              size={23}
              onClick={() => setTicketTypeIsOpen(false)}
            />
            <HeaderText>{t("header")}</HeaderText>
          </ArrowTextContainer>
          <CloseButton onClick={() => setTicketTypeIsOpen(false)}>
            <CloseIcon size={23} />
          </CloseButton>
        </HeaderContainer>
        <InputContainer>
          <InfoTicketTypeContainer>
            <TicketTypeInfoText style={{ gridArea: "loteInfoText" }}>
              {t("info-text")}
            </TicketTypeInfoText>
            <div style={{ gridArea: "nomeInput" }}>
              <DescriptionInputText>{t("label.name")}</DescriptionInputText>
              <CustomInput
                type="text"
                placeholder={t("placeholder.name")}
                value={name || ""}
                onChange={(e) => setName(e.target.value)}
                className="form-control"
              />
              {errors?.name && (
                <TextH6 className="text-danger ml-2 mt-1">
                  <TextSmall>{t("error.required")}</TextSmall>
                </TextH6>
              )}
            </div>
            <div style={{ gridArea: "quantityInput" }}>
              <RowContainer>
                <DescriptionInputText>
                  {t("label.quantity")}
                </DescriptionInputText>
              </RowContainer>
              <EditTicketTypeInput
                required
                min={0}
                type="number"
                name="qty"
                placeholder={t("placeholder.quantity")}
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                className="form-control"
              />
            </div>
            <div style={{ gridArea: "percentageInput" }}>
              <RowContainer>
                <DescriptionInputText>
                  {t("label.percentage")}
                </DescriptionInputText>
              </RowContainer>
              <ValueInputContainer className="d-flex flex-row form-control p-0">
                <span
                  style={{ padding: "0 5px 0 10px" }}
                  className="d-flex justify-content-center align-items-center"
                >
                  %
                </span>
                <EditTicketTypeInput
                  style={{ outline: 0, border: "none" }}
                  required
                  type="number"
                  name="percentage"
                  value={percentage}
                  onChange={(e) => setPercentage(e.target.value)}
                  max={100}
                />
              </ValueInputContainer>
            </div>
            <ErroText
              style={{
                gridArea: "erroText",
              }}
            >
              {error && error}
            </ErroText>
            <SubmitButtonContainer style={{ gridArea: "submitButton" }}>
              <SubmitButton
                disabled={isSubmitting}
                className="btn btn-primary c3 btn-block"
                onClick={handleSubmit(createTicketType)}
                type="button"
              >
                <SubmitButtonText>{t("save-button")}</SubmitButtonText>
              </SubmitButton>
            </SubmitButtonContainer>
          </InfoTicketTypeContainer>
        </InputContainer>
      </ModalContainer>
    </ModalComponent>
  );
}

// /////////////////////////////////// CONTAINERs /////////////////////////

const SubmitButtonContainer = styled.div`
  padding-bottom: ${SPACINGS.space4};
`;

const ValueInputContainer = styled.div`
  border: 1px gray solid;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const InputContainer = styled.div`
  padding: 0 ${SPACINGS.space0} 0 ${SPACINGS.space0};
  @media ${breakPoints.tablet} {
    padding: 0 ${SPACINGS.space4} 0 ${SPACINGS.space4};
  }
`;

const HeaderContainer = styled.div`
  padding: ${SPACINGS.space4};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  border-bottom: 2px ${COLORS.gray} solid;
`;

const InfoTicketTypeContainer = styled.div`
  display: grid;
  column-gap: 15px;
  padding-top: ${SPACINGS.space3};
  height: 300px;
  row-gap: 5px;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    "loteInfoText loteInfoText"
    "nomeInput quantityInput"
    "editText editText"
    "percentageInput ."
    "minBuy  maxBuy"
    "selectPonto selectPonto"
    "switchCPF maxCPF"
    "description description"
    "submitButton ."
    "erroText erroText";

  @media ${breakPoints.tablet} {
    row-gap: 10px;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-template-areas:
      "loteInfoText  loteInfoText loteInfoText loteInfoText"
      "nomeInput quantityInput"
      "editText editText editText editText"
      "qtyInput percentageInput minBuy  maxBuy"
      "selectPonto selectPonto selectPonto selectPonto"
      ". . switchCPF maxCPF"
      "description description description description"
      "submitButton submitButton";
  }
`;

const ModalContainer = styled.div`
  padding: 0 ${SPACINGS.space2} 0 ${SPACINGS.space2};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  overflow: visible;
`;

// /////////////////////////////////// TEXTs //////////////////////////////

const ArrowTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SubmitButtonText = styled.span`
  color: ${COLORS.white};
  font-size: 18px;
  height: 38px;
  white-space: nowrap;
`;
const DescriptionInputText = styled.span`
  font-size: 12px;
  color: ${COLORS.darkGray};
  padding-right: ${SPACINGS.space0};
  white-space: nowrap;
  margin-top: 4px;
`;
const HeaderText = styled.span`
  padding-left: ${SPACINGS.space2};
  color: ${COLORS.darkGray};
  text-align: center;
  font-size: 12px;
  white-space: nowrap;
`;
const TicketTypeInfoText = styled.span`
  color: ${COLORS.black};
  text-align: center;
  font-weight: bold;
  font-size: 13px;
  display: flex;
  white-space: nowrap;
`;
const ErroText = styled.span`
  padding-left: 10px;
  color: red;
  height: 38px;
`;
// /////////////////////////////////// INPUTs /////////////////////////////

const CustomInput = styled.input`
  border: 1px solid ${COLORS.gray};
  border-radius: 5px;
  width: 100%;
  height: 38px;
`;
const EditTicketTypeInput = styled.input`
  border: 1px solid ${COLORS.gray};
  border-radius: 5px;
  width: 120px;
  height: 38px;
  /* &:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		-webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
		-webkit-transition-delay: 9999s;
	} */
  @media ${breakPoints.mobile} {
    width: 100%;
  }
`;
// /////////////////////////////////// BUTTONs ////////////////////////////

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
`;
const SubmitButton = styled.button`
  border: none;
  background-color: ${COLORS.middleBlue};
  border-radius: 10px;
  height: 38px;
  width: 100%;
  &:hover {
    background-color: ${COLORS.darkBlue};
  }
`;
// /////////////////////////////////// ICONs //////////////////////////////

const BackArrowIcon = styled(IoMdArrowBack)`
  color: ${COLORS.gray};
  cursor: pointer;
  font-size: 15px;
`;
const CloseIcon = styled(IoIosCloseCircleOutline)`
  color: ${COLORS.gray};
  font-size: 15px;
`;
const ModalComponent = styled(Modal)`
  width: 100%;
  @media ${breakPoints.sideBarClosed} {
    width: 55%;
  }
`;
const TextH6 = styled.h6``;

const TextSmall = styled.small``;
