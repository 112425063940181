import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "./styles.css";

const EventAddress = ({ stepData, setStepData }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const {
    name,
    street,
    state,
    city,
    neighborhood,
    number,
    complement,
    zip_code,
  } = stepData.location;
  const [showFields, setShowFields] = useState(false);
  const [zipcode, setZipcode] = useState(zip_code || "");

  useEffect(() => {
    const fetchAddressByZipcode = async () => {
      if (zipcode.length >= 8) {
        setIsLoading(true);

        try {
          const { data } = await axios.get(
            `https://viacep.com.br/ws/${zipcode}/json/`,
            { timeout: 5000 }
          );

          if (!data.erro && data.localidade) {
            setStepData((prevState) => ({
              ...prevState,
              location: {
                ...prevState.location,
                street: data.logradouro,
                neighborhood: data.bairro,
                city: data.localidade,
                state: data.uf,
                zip_code: data.cep,
                formatted_address:
                  data.logradouro +
                  ", " +
                  number +
                  " - " +
                  data.bairro +
                  ", " +
                  data.localidade +
                  ", " +
                  data.uf +
                  " - " +
                  data.cep,
              },
            }));
          } else if (
            street &&
            number &&
            neighborhood &&
            city &&
            state &&
            zip_code
          ) {
            setStepData((prevState) => ({
              ...prevState,
              location: {
                ...prevState.location,
                formatted_address:
                  street +
                  ", " +
                  number +
                  " - " +
                  neighborhood +
                  ", " +
                  city +
                  ", " +
                  state +
                  " - " +
                  zip_code,
              },
            }));
          }
        } catch (error) {
          console.error("Erro ao buscar o CEP:", error);
        } finally {
          setIsLoading(false);
          setShowFields(true);
        }
      }
    };

    fetchAddressByZipcode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipcode, setStepData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "zip_code") {
      setZipcode(value.replace(/\D/g, ""));
    }

    setStepData((prevState) => ({
      ...prevState,
      location: {
        ...prevState.location,
        [name]: value,
      },
    }));
  };

  const handleZipcodeKeypress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div>
      <Row className="pr-2">
        <Col md={5} className="pr-0">
          <div>
            <label className="form-label">
              {t("events:createEvent:zipCode")}
            </label>
            <input
              type="text"
              className="form-control"
              name="zip_code"
              value={zip_code}
              placeholder={t("events:createEvent:zipCode")}
              onChange={handleInputChange}
              onKeyPress={handleZipcodeKeypress}
              maxLength="8"
            />
          </div>
        </Col>
        {isLoading ? (
          <Col className="cep-info">
            <span>{t("events:createEvent:searchingZipCode")}</span>
          </Col>
        ) : (
          <Col className="cep-info">
            <a
              rel="noopener noreferrer"
              href="https://buscacepinter.correios.com.br/app/endereco/index.php"
              target="_blank"
            >
              {t("events:createEvent:dontKnowMyZipCode")}
            </a>
          </Col>
        )}
        {showFields && (
          <Row className="m-0">
            <Col md={5} className="form-group pr-0">
              <label className="form-label">
                {t("events:createEvent:address")}
              </label>
              <input
                type="text"
                className="form-control"
                name="street"
                placeholder={t("events:createEvent:address")}
                value={street}
                onChange={handleInputChange}
                readOnly={isLoading}
              />
            </Col>
            <Col md={2} className="form-group pr-0">
              <label className="form-label">
                {t("events:createEvent:number")}
              </label>
              <input
                type="text"
                className="form-control"
                name="number"
                placeholder={t("events:createEvent:number")}
                value={number}
                onChange={handleInputChange}
                readOnly={isLoading}
              />
            </Col>
            <Col md={5} className="form-group pr-0">
              <label className="form-label">
                {t("events:createEvent:complement")}
              </label>
              <input
                className="form-control"
                name="complement"
                value={complement}
                placeholder={t("events:createEvent:complement")}
                onChange={handleInputChange}
              />
            </Col>
            <Col md={5} className="form-group pr-0">
              <label className="form-label">
                {t("events:createEvent:neighborhood")}
              </label>
              <input
                type="text"
                className="form-control"
                name="neighborhood"
                placeholder={t("events:createEvent:neighborhood")}
                value={neighborhood}
                onChange={handleInputChange}
                readOnly={isLoading}
              />
            </Col>
            <Col md={4} className="form-group pr-0">
              <label className="form-label">
                {t("events:createEvent:city")}
              </label>
              <input
                type="text"
                className="form-control"
                name="city"
                placeholder={t("events:createEvent:city")}
                value={city}
                onChange={handleInputChange}
                readOnly={isLoading}
                disabled={zipcode.length > 0 ? true : false}
              />
            </Col>
            <Col md={3} className="form-group pr-0">
              <label className="form-label">
                {t("events:createEvent:state")}
              </label>
              <input
                type="text"
                className="form-control"
                name="state"
                placeholder={t("events:createEvent:state")}
                value={state}
                disabled={zipcode.length > 0 ? true : false}
                onChange={handleInputChange}
                readOnly={isLoading}
              />
            </Col>
            <Col md={5} className="form-group pr-0">
              <label className="form-label">
                {t("events:createEvent:fantasyAddressName")}
              </label>
              <input
                className="form-control"
                name="name"
                placeholder={t("events:createEvent:fantasyAddressNameExample")}
                value={name}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
        )}
      </Row>
    </div>
  );
};

export default EventAddress;
