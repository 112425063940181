import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import PropTypes from "prop-types";

TableSkeleton.propTypes = {
  showOrgColumn: PropTypes.bool,
  showEventColumn: PropTypes.bool,
  rows: PropTypes.number,
};

export function TableSkeleton({ showOrgColumn, showEventColumn, rows }) {
  return (
    <>
      {Array.from(Array(rows), (_, i) => {
        return (
          <tr key={i} id="trow-transfers">
            <td>
              <Skeleton baseColor="#EAE9ED" />
            </td>
            <td>
              <Skeleton baseColor="#EAE9ED" />
            </td>
            <td>
              <Skeleton baseColor="#EAE9ED" />
            </td>
            <td>
              <Skeleton baseColor="#EAE9ED" />
            </td>
            <td>
              <Skeleton baseColor="#EAE9ED" />
            </td>
            <td>
              <Skeleton baseColor="#EAE9ED" />
            </td>
            <td>
              <Skeleton baseColor="#EAE9ED" />
            </td>
            {showOrgColumn && (
              <td>
                <Skeleton baseColor="#EAE9ED" />
              </td>
            )}
            {showEventColumn && (
              <>
                <td>
                  <Skeleton baseColor="#EAE9ED" />
                </td>
              </>
            )}
          </tr>
        );
      })}
    </>
  );
}
